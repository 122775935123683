.markdown {
  max-width: none;
  letter-spacing: 0em;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4 {
  line-height: 1.25;
}

.markdown p {
  line-height: 1.625;
}

.markdown strong {
  letter-spacing: -0.025em;
}

.markdown em,
.markdown blockquote,
.markdown blockquote p {
  font-style: normal;
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variation-settings:
    "wght" var(--font-weight, 400),
    "ital" 8;
}

.markdown blockquote {
  margin: 0px;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: hsl(var(--heroui-foreground-500) / 0.3);
  background-color: transparent;
  padding: 1rem;
  font-family: var(--font-sans), ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: -0.025em;
}

.markdown blockquote p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.markdown blockquote a {
  font-weight: 400;
}

.markdown a {
  font-weight: 700;
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-text-opacity)));
  text-decoration-line: underline;
  text-decoration-color: #f97316;
  text-underline-offset: 4px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.markdown a:hover {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity, 1));
}

.markdown a:has(> img) {
  text-decoration-line: none;
}

.markdown ol > li {
  list-style-type: decimal;
}

.markdown ul > li {
  list-style-type: disc;
}

.markdown a > img {
  margin: 0px;
  padding: 0px;
}

.markdown a:has(> img)::after {
  content: none;
}

.markdown figcaption {
  margin-top: 0.5rem;
  padding-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: italic;
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground-700) / var(--heroui-foreground-700-opacity, var(--tw-text-opacity)));
}

.markdown pre {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-text-opacity)));
}

.markdown pre > code {
  border-color: transparent !important;
  background-color: transparent !important;
  padding: 1rem !important;
  font-family: var(--font-mono), ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.markdown hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
